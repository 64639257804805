import React, { useEffect, useState } from "react";
import { graphql, StaticQueryDocument } from "gatsby";
import "../styles/general.styles.scss";
import "../styles/newsletter.styles.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import { CircleConfig } from "../components/ContentRowComponent/ContentRowComponent.types";
import { useWindowSize } from "../utils/useWindowSize";
import ContentRowComponent from "../components/ContentRowComponent/ContentRowComponent";
import SeperatorComponent from "../components/SeperatorComponent/SeperatorComponent";
import BoxComponent from "../components/BoxComponent/BoxComponent";
import OneIcon from "../assets/svg/one.svg";
import TwoIcon from "../assets/svg/two.svg";
import ThreeIcon from "../assets/svg/three.svg";

const NewsletterPage: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [separatorHeight, setSeparatorHeight] = useState<number>(600);
  const [circleConfigs, setCircleConfigs] = useState<CircleConfig[]>([
    {
      color: "#f89a26",
      radius: 2000,
      weight: 8,
      active: true,
      horiz: -500,
      left: true,
    },
  ]);

  /**
   *
   */
  useEffect(() => {
    switch (true) {
      case width > 769:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 2000,
            weight: 8,
            active: true,
            horiz: -500,
            left: true,
          },
        ]);
        setSeparatorHeight(600);
        break;

      case width > 600 && width <= 769:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 1200,
            weight: 8,
            horiz: -340,
            top: -300,
            active: true,
          },
        ]);
        setSeparatorHeight(150);
        break;

      case width <= 600:
      default:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 1000,
            weight: 8,
            active: true,
            horiz: -320,
            top: -560,
          },
        ]);
        setSeparatorHeight(1);
        break;
    }
  }, [width]);

  return (
    <LayoutComponent seo={{ title: t("page.newsletter.title") }} sub>
      <ContentRowComponent className="column-wrapper">
        <div className="newsletter-sub-page">
          <div className="headline-h2">Nur noch 3 Schritte …</div>
          <div className="text">
            … bis deine Anmeldung abgeschlossen ist.
            <br />
            Damit du E-Mails von uns empfangen kannst, ist es{" "}
            <b>unbedingt notwendig</b>,<br />
            dass du deine Anmeldung noch bestätigst.
          </div>
          <div className="next">So geht es weiter</div>

          <div className="content-row-box-wrapper">
            <BoxComponent borderColor="#F89B06" type="small" icon={<OneIcon />}>
              <div className="box-title small">Öffne deinen Posteingang</div>
              <div className="flex-grow-1 box-text small">
                und schaue dort nach unserer E-Mail mit dem Betreff: [90.10.]
                Ein Klick fehlt noch.
              </div>
            </BoxComponent>

            <BoxComponent borderColor="#F89B06" type="small" icon={<TwoIcon />}>
              <div className="box-title small">Klicke den Bestätigungslink</div>
              <div className="flex-grow-1 box-text small">
                (Double-Opt-in) in der E-Mail, um Deine Anmeldung abzuschließen.
              </div>
            </BoxComponent>

            <BoxComponent
              borderColor="#F89B06"
              type="small"
              icon={<ThreeIcon />}
            >
              <div className="box-title small">Lege in deinem Adressbuch </div>
              <div className="flex-grow-1 box-text small">
                oder E-Mail-Programm einen Eintrag mit meinen Daten an:
                <br />
                Oliver Schacke [90.10.] oliver@9010.com
              </div>
            </BoxComponent>
          </div>
        </div>
      </ContentRowComponent>
      <ContentRowComponent
        circleConfig={circleConfigs[0]}
        className="minimal-z-index"
      >
        <SeperatorComponent height={separatorHeight} />
      </ContentRowComponent>
    </LayoutComponent>
  );
};

export default NewsletterPage;

export const query: StaticQueryDocument = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    medicineParallaxImage: file(relativePath: { eq: "9010-medizin.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeParallaxImage: file(relativePath: { eq: "9010-zuhause.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jobParallaxImage: file(relativePath: { eq: "9010-beruf.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sportParallaxImage: file(relativePath: { eq: "9010-sport.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
